/* Targeting the autofill */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/* Changing the text color, if necessary */
input:-webkit-autofill {
  -webkit-text-fill-color: black !important;
}

.form-incentive-input > input::placeholder {
  color: black;
  opacity: 0.5; /* Firefox */
}

::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: blackred;
}
