body {
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0 0;
  background: #f3f3f3;
  height: 100vh;
}

a {
  text-decoration: none;
}
.fa,
.fas {
  font-family: "FontAwesome";
  font-weight: 900;
}

.cursor-pointer {
  cursor: pointer;
}

.w-content {
  width: fit-content;
}
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ml-9rem {
  margin-left: 9rem;
}

.logo {
  margin: 3px 0 0 20px;
}
.logo a {
  display: block;
}
.logo img {
  margin-right: 4px;
}
.user-profile {
  text-align: right;
  letter-spacing: 0.055em;
  font-weight: 400;
  font-size: 15px;
  line-height: 40px;
  color: #575757;
  margin: 5px 20px 5px 0;
  font-family: "Roboto", sans-serif;
}
.user-profile .user-pic img {
  width: 40px;
  border-radius: 50%;
}

.flex-direction-column {
  flex-direction: column;
}

.mw-none {
  max-width: none;
}

.flex-grow-1 {
  flex-grow: 1;
}

.navigation {
  margin: 0 0;
}
.navigation .nav {
  margin: 0 0 0 0;
  display: block;
}
.navigation .nav .nav-item {
  position: relative;
  margin-top: 5px;
}
.navigation .nav .nav-item > a {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #222222;
  margin: 0;
  padding: 18px 0;
  display: block;
  text-decoration: none;
  text-align: center;
}
.nav-img {
  width: 36px;
  height: 36px;
  display: block;
  text-align: center;
  margin: 0 auto;
}
.navigation .nav .nav-item > a svg path {
  fill: rgba(87, 87, 87, 1);
}
.navigation .nav .nav-item > a:hover svg path {
  fill: rgba(0, 84, 158, 1);
}
.navigation .nav .nav-item.active {
  background: #f3f3f3;
}
.nav-name {
  display: none;
}
.main-content {
  padding-top: 51px;
  margin-left: 110px;
  padding-left: 20px;
  padding-right: 20px;
  background: #f3f3f3;
}
.page-title-row {
  font-family: "Inter", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 7px 0 5px;
}
.page-title-row h1 {
  margin: 0;
  color: #064074;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.page-title-row .breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
}
.page-title-row .breadcrumb li {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #222222;
  margin: 0;
  padding: 0;
}
.page-title-row .breadcrumb li a {
  font-size: 14px;
  line-height: 16px;
  color: #222222;
  font-weight: 400;
}
.page-title-row .breadcrumb li.active {
  color: #00549e;
  font-weight: 600;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.3rem;
  color: #6c757d;
  content: ">";
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem !important;
}

.custom-radio {
  margin-bottom: 10px;
}
.custom-radio {
  position: relative;
}
.custom-radio input {
  display: none;
}
input[type="radio"] ~ label {
  padding-left: 30px;
  color: #575757;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}
input[type="radio"] ~ label:after,
input[type="radio"] ~ label:before {
  position: absolute;
  content: "";
  display: inline-block;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}
input[type="radio"] ~ label:before {
  left: 0;
  width: 22px;
  height: 22px;
  background-color: #fff;
  border: 2px solid #00549e;
}
input[type="radio"] ~ label:after {
  width: 10px;
  height: 10px;
  left: 6px;
  background: #00549e;
  opacity: 0;
}
input[type="radio"]:checked ~ label:after {
  opacity: 1;
}

ul.tab-list {
  display: flex;
  justify-content: center;
  margin-bottom: 1px;
}
ul.tab-list li {
}
ul.tab-list li a {
  margin: 0 25px 3px;
  color: #575757;
  font-size: 18px;
  display: block;
  font-weight: 400;
  line-height: 24px;
  border-bottom: 4px solid transparent;
}
ul.tab-list li.active a {
  color: #00549e;
  font-weight: 700;
  border-color: #00549e;
}
.white-card {
  margin: 7px 0 0 0;
  border: 1px solid #d9d9d9;
  background: #fff;
  padding: 20px 0;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25);
}
.form-style-box-1 {
  padding: 5px 0px;
}
.white-card .form-style-box-1 .form-label {
  margin: 0 25px 0 0;
  color: #222;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}
.form-style-box-1 .form-control {
  max-width: 260px;
  display: inline-block;
}
.white-card .form-control {
  border: 0.63px solid #a6a6a6;
  // background: #FFF;
  height: 34px;
  color: #222;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  padding: 9px 20px;
  border-radius: 0;
  box-shadow: none;
}
.white-card .form-select {
  border: 0.63px solid #a6a6a6;
  height: 36px;
  color: #222;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  padding: 10px 20px;
  border-radius: 0;
  box-shadow: none;
}
.text-right {
  text-align: right !important;
}

.white-card-header {
  padding: 0 40px;
}

.white-card h2 {
  margin: 0 0;
  padding: 0 0 10px;
  color: #000;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}
.white-card-body {
  margin: 0 40px 0 40px;
}
.form-row-1 {
  padding: 0 30px;
}
.white-card-body legend {
  color: #00549e;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  float: none;
  margin-bottom: 5px;
}
.flex-row-2-col {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
}
.radio-row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 10px;
  max-width: 620px;
}
.radio-row-1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
  max-width: 315px;
}
.divider-line {
  margin: 20px 0;
  height: 1px;
  background: #c4c4c4;
}
.form-row-1 .form-label {
  color: #575757;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0 0 4px;
}
.form-row-1 textarea.form-control {
  border: 0.63px solid #a6a6a6;
  background: #fff;
  height: 106px;
  color: #222;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  padding: 10px 20px;
  border-radius: 0;
  box-shadow: none;
}
.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
}
.upload-box {
  border: 0.63px dashed #575757;
  background: #fafafa;
  padding: 20px 20px;
  text-align: center;
}
.upload-box p {
  color: #222;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-transform: capitalize;
  margin: 0;
}
.upload-box p a {
  color: #00549e;
  text-decoration: underline;
}
.calendar-box {
  position: relative;
  display: inline-block;
}
.calendar {
  position: absolute;
  right: 10px;
  top: 4px;
}

.white-card-footer {
  text-align: center;
  padding: 25px 0 20px;
}
.white-card-footer .btn {
  color: #00549e;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  height: 42px;
  min-width: 164px;
  padding: 10px 20px;
  border: 1px solid #00549e;
  border-radius: 0;
  text-transform: uppercase;
  margin: 0 5px;
  box-shadow: none;
}
.white-card-footer .btn:hover {
  background: #00549e;
  color: #fff;
}
.white-card-footer .btn-primary {
  background: #00549e;
  color: #fff;
}
.white-card-footer .btn-primary:hover {
  color: #00549e;
  background: #fff;
}

.tab-panel-card {
  border: 1px solid #d9d9d9;
  background: #fff;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 8px 20px 10px;
}
ul.tab-list-1 {
  display: flex;
  align-items: center;
  padding: 0 45px 0 55px;
}
ul.tab-list-1 li {
  margin-right: 30px;
}
ul.tab-list-1 li:last-child {
  margin-right: 0px;
}
ul.tab-list-1 li a {
  padding: 10px 20px;
  color: #818181;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  text-align: center;
  display: block;
  min-width: 185px;
}
ul.tab-list-1 li a:hover {
  color: #00549e;
}
ul.tab-list-1 li.active a {
  background: #00549e;
  color: #fff;
  font-weight: 700;
  letter-spacing: 1px;
}

.box-style-1 {
  padding: 0 25px 0 35px;
}
.width120 {
  width: 120px;
}
// .form-control:read-only{
//     border: 0.63px solid #575757;
//     background: #FAFAFA;
// }
.radio-style-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
.radio-style-1 .label-1 {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  margin-right: 22px;
  display: inline-block;
}
.radio-style-1 .custom-radio {
  margin-bottom: 0;
}
.radio-style-1 > .custom-radio {
  margin-right: 40px;
}
.block {
  display: block !important;
}
.row-style-1 {
  padding: 0 25px 0 35px;
}

.files {
  position: relative;
  background: #fafafa;
  border: 1px dashed #00549e;
  border-radius: 10px;
  width: 100%;
  height: 137px;
  text-align: center;
  padding: 30px 10px;
}
.files > span {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #222222;
}
.files span a {
  color: #00549e;
}
.row-style-1 .btn-primary,
.btn-primary {
  background: #00549e;
  width: 160px;
  height: 42px;
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 0;
  margin: 47px 0 0 30px;
}
.manual {
  float: none;
  max-width: 96.5%;
}
.files p {
  color: #222;
  font-size: 16px;
  font-weight: 600;
  line-height: 133%;
  margin: 20px 10px 0;
  text-align: left;
}
.styled-checkbox {
  position: absolute;
  opacity: 0;
}
.styled-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
  color: #575757;
  font-size: 16px;
  font-weight: 400;
}
.styled-checkbox + label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 22px;
  height: 22px;
  background: white;
  border: 2px solid #575757;
}
.styled-checkbox:hover + label:before {
  border-color: rgba(0, 84, 158, 1);
}
.styled-checkbox:focus + label:before {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.12);
}
.styled-checkbox:checked + label:before {
  background: #fff;
  border-color: rgba(0, 84, 158, 1);
}
.styled-checkbox:checked + label {
  color: rgba(0, 84, 158, 1);
}

.styled-checkbox:disabled + label {
  color: #b8b8b8;
  cursor: auto;
}
.styled-checkbox:disabled + label:before {
  box-shadow: none;
  background: #ddd;
}
.styled-checkbox:checked + label:after {
  content: "";
  position: absolute;
  left: 6px;
  top: 12px;
  background: rgba(0, 84, 158, 1);
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 rgba(0, 84, 158, 1), 4px 0 0 rgba(0, 84, 158, 1),
    4px -2px 0 rgba(0, 84, 158, 1), 4px -4px 0 rgba(0, 84, 158, 1),
    4px -6px 0 rgba(0, 84, 158, 1), 4px -8px 0 rgba(0, 84, 158, 1);
  transform: rotate(45deg);
}

.box-style-2 {
  padding: 0 60px;
}
.box-style-2 label {
  color: #575757;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  text-transform: capitalize;
  margin-bottom: 2px;
}
.form-box-white {
  margin-top: 10px;
  padding: 20px 45px;
  background: #fff;
  border: 1px solid #d9d9d9;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25);
}
.form-box-white label.legend,
label.legend {
  color: #222;
  font-size: 16px;
  font-weight: 600;
  min-width: 450px;
}
.form-box-white .custom-radio,
.inline-radios {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 0;
}
.box-style-3 {
  padding: 0 40px;
  position: relative;
}
.inline-block {
  display: inline-block !important;
}
.marginT0 {
  margin-top: 0 !important;
}
.card-footer-1 {
  padding: 30px 0 0 0 !important;
}
.settilement-policy {
  max-width: 480px;
  float: none;
  margin-top: 40px;
}
.upload-btn {
  text-align: center;
  max-width: 480px;
}
.upload-btn .btn {
  margin-top: 20px;
}

.right-box-1 {
  margin-left: 50px;
}
.vertical-divider {
  background: #d9d9d9;
  width: 1px;
  left: 58%;
  top: -10px;
  bottom: -10px;
  position: absolute;
}
.right-box-1 {
  text-align: left;
  margin-top: 20px;
}
.right-box-1 h3 {
  color: #222;
  font-size: 18px;
  font-weight: 600;
  line-height: 120.523%;
  text-decoration-line: underline;
  margin: 0 0 20px;
}
.table-1 th {
  color: #575757;
  font-size: 16px;
  font-weight: 600;
  padding: 3px 10px;
  border: none;
}
.table-1 td {
  color: #575757;
  font-size: 16px;
  font-weight: 400;
  padding: 3px 10px;
  border: none;
}
.table-1 td:last-child {
  color: #00549e;
}
.table-title {
  max-width: 460px;
  margin: 0 auto;
}
.table-title th {
  color: #575757;
  font-size: 16px;
  font-weight: 600;
  padding: 0px 10px;
  border: none;
}
.table-title td {
  color: #575757;
  font-size: 16px;
  font-weight: 400;
  padding: 0px 10px;
  border: none;
}
.table-title td:last-child {
  color: #00549e;
  font-weight: 600;
}
.table-content {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 40px;
}
.table-content > thead th {
  background: #808184;
  color: #fff;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}
.table-striped > tbody > tr:nth-of-type(odd) > * {
  background: #fff;
  box-shadow: none;
}
.table-striped > tbody > tr:nth-of-type(even) > * {
  background: #f5f5f5;
}
.table-striped > tbody td {
  color: #222;
  font-size: 16px;
  font-weight: 400;
  padding: 3px 10px;
}
.table-striped > tbody tr.selected td {
  color: #00549e;
}
.flex-row-1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-row-1 .legend {
  display: inline;
  margin-right: 20px;
}
.flex-row-1 .custom-radio {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 2px;
}
.flex-row-1 .custom-checkbox {
  display: inline-block;
  margin-right: 30px;
}
.table-incentive {
  border-radius: 10px;
  padding: 15px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 10px;
}
.table-incentive .table-content {
  box-shadow: none;
  margin-bottom: 0;
}
.table-incentive .table-content td {
  border: none;
}
.gray-circle {
  background: url("../../assets/images/gray-circle.png") no-repeat 0 0;
  width: 35px;
  height: 35px;
  text-align: center;
  display: block;
  margin: 5px auto 0;
  line-height: 35px;
}
.incentive-title {
  text-align: center;
  color: #222;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}
.center-1 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

/*==========Custom swithc===*/
.custom-switch {
  padding-left: 2.25rem;
  position: relative;
  display: block;
  min-height: 1.5rem;
}
.custom-control-input1 {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-switch .custom-control-label1 {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #212529;
  margin-left: 7px;
}
.custom-control-label1 span {
  display: inline-block;
  margin-left: 20px;
  margin-top: 5px;
}
.custom-control-label1::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 20px;
  pointer-events: none;
  content: "";
  background-color: #d8dae5;
  border: #d8dae5 solid 1px;
}
.custom-control-label1::before {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
.custom-switch .custom-control-label1::before {
  left: -2.95rem;
  width: 40px;
  pointer-events: all;
  border-radius: 0.7rem;
}
.custom-control-label1::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}
.custom-switch .custom-control-label1::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.85rem + 2px);
  width: calc(1rem);
  height: calc(1rem);
  background-color: #fff;
  border-radius: 0.7rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-transform 0.15s ease-in-out;
}
.custom-control-input1:checked ~ .custom-control-label1::before {
  color: #00549e;
  border-color: #00549e;
  background-color: #00549e;
}
.custom-switch .custom-control-input1:checked ~ .custom-control-label1::after {
  background-color: #fff;
  -webkit-transform: translateX(0.75rem);
  transform: translateX(0.75rem);
  left: calc(-2.85rem + 9px);
}
.drop-area {
  width: 100%;
  height: 115px;
  background: #ffffff;
  border: 0.629601px dashed #575757;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  padding: 30px;
  color: #222222;
}
#search::placeholder {
  color: #d9d9d9;
}

.saveBtnClient {
  background-color: white;
  text-transform: none !important;
}

.banking-datail {
  width: 50%;
}

.btn-back-blue {
  background-color: #00549e;
  color: white !important;
}

.btn-blue-save {
  color: white;
  background-color: #00549e;
  font-weight: bold;
}

.react-tagsinput-tag {
  background-color: #f5f5f5;
  border: none;
  color: black;
}

.react-tagsinput {
  border: 0.5px solid #a6a6a6;
}

.react-tagsinput:focus {
  border: 0.5px solid #a6a6a6;
}

.react-tagsinput input {
  color: black !important;
}

.react-tagsinput input::placeholder {
  color: black;
}

.border-gray {
  border: 0.63px solid #a6a6a6 !important;
}

.createClientReportTable table tbody tr:nth-child(even) > * {
  background-color: #eef3f6 !important; /* Alternate color for odd rows */
}

.btnClientProfiling {
  background-color: #00549e !important;
  color: white !important;
}
.btnClientProfiling:active {
  box-shadow: 3px 2px 5px #00549e !important;
}
.hidden-input {
  display: none;
}

.custom-control-input {
  display: none !important;
}

.settlement-table thead {
  background-color: transparent !important;
  background: transparent !important;
  box-shadow: none !important;
  tr {
    border: none !important;
  }
  tr th {
    color: #575757 !important;
    border: none !important;
  }
}

.settlement-table td {
  font-weight: 300 !important;
}

.action_loanFullwidth {
  border: 1px solid #d9d9d9;
  background: #fff;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25);
}

.btn-add-report {
  color: white !important;
  font-size: 14px;
}

.browse-text {
  color: #00549e;
}
input[name="callingScript"] {
  font-weight: 600;
}

input[name="callingScript"]::placeholder {
  color: black !important;
  font-weight: bold;
  font-size: 14px;
}

.breadcrumb-item-second::before {
  color: #00549e !important;
}

.dropdown-item {
  border: 1px solid #d9d9d9;
  background: #fff;
  width: auto;
  box-shadow: 1px 1px 4px 0px #00000040;
  cursor: pointer;
}

.dropdown-item:active {
  background: #d9d9d9;
  color: white;
}

.inline-items-box {
  display: inline;
}

.view-client-filter {
  width: 170px !important;
}

.view-client-filter-search {
  width: 250px !important;
}
.inline-items-box * * {
  display: inline;
  margin: 10px;
}

.srv-validation-message {
  color: red;
}

.btn-add-portfolio:hover {
  .addPortfolio-client {
    fill: white !important;
  }
}

.addIncentiveBox {
  gap: 1%;
}

.addIncentiveBox input[type="submit"] {
  background-color: gray;
  color: white;
  border-radius: 1px;
  border: none;
}

.addIncentiveBox input {
  width: 35%;
}

.text-area-script {
  font-weight: 600;
  border-radius: 10px;
  height: 100%;
  padding: 40px !important;
  border: 1px solid red;
}

@media (min-width: 1200px) and (max-width: 1400px) {
}
@media (min-width: 1450px) and (max-width: 1920px) {
}

//view client select dropdown styles
.view-select {
  padding: 6px 20px;
  height: 35px;
  border: 1px solid #575757;
  width: 100%;
}

//view client new portfolio button
.portfolio-submit-button {
  cursor: pointer;
  border: 1px solid #168579;
  background-color: #168579;
  border-radius: 30px;
  padding: 10px 20px;
  color: #ffff;
  size: 16px;
  font-weight: 700;
  line-height: 19.36px;
}
.portfolio-submit-button:hover {
  color: #fff;
}

//view client top row dropdowns

.toggle-switch {
  width: 50px;
  height: 25px;
  background-color: #ddd;
  border-radius: 25px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-switch.active {
  background-color: #00549e;
}

.toggle-knob {
  width: 25px;
  height: 25px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease;
}

.toggle-switch.active .toggle-knob {
  transform: translateX(25px);
}

/* Hide the spinner on input of type number */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
