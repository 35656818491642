.header-style-1{
    background: #D9D9D9 !important;
    background-image: none !important;
    padding: 0px 0 1px;
    .nav-style-1{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .navbar-brand{
            margin-left: 20px;
            padding-top: 0;
            padding-bottom: 0;
            img{
                height: 50px;
                display: inline-block;
                margin-right: 5px;
            }
        }
        .right-header{
            margin-right: 20px ;
            .nav-link{
                padding: 0;
                text-align: right;
            }
            .profile-pic{
                width: 40px; 
                border-radius: 50%;
            }
            .user-detail{
                letter-spacing: 0.055em;
                font-weight: 400;
                font-size: 15px;
                line-height: 40px;
                color: #575757;
                margin-top: 5px;
                margin-right: 10px;
                font-family: 'Roboto', sans-serif;
                span:last-child{
                    color: #000; 
                }
            }
        }
    }
}

//Custom switch

.custom-control1{
    z-index: 1000;
}

.custom-switch {
    padding-left: 2.25rem;
    position: relative;
    display: block;
    min-height: 1.5rem;
  }
  .custom-control-input1 {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  .custom-switch .custom-control-label1 {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;  
    color: #212529;
    margin-left: 7px;
    cursor: pointer;
  }
  .custom-control-label1 span{
    display: inline-block;
    margin-left: 20px;
    margin-top: 5px;
  }
  .custom-control-label1::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 20px;
    pointer-events: none;
    content: "";
    background-color: #D8DAE5;
    border: #D8DAE5 solid 1px;
  }
  .custom-control-label1::before  {
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
  .custom-switch .custom-control-label1::before {
    left: -2.95rem;
    width: 40px;
    pointer-events: all;
    border-radius: 0.7rem;
  }
  .custom-control-label1::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
  }
  .custom-switch .custom-control-label1::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.85rem + 2px);
    width: calc(1rem );
    height: calc(1rem );
    background-color: #fff;
    border-radius: 0.7rem;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out;
    transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out;
  }
  .custom-control-input1:checked~.custom-control-label1::before {
    color: #00549E;
    border-color: #00549E;
    background-color: #00549E;
  }
  .custom-switch .custom-control-input1:checked~.custom-control-label1::after {
    background-color: #fff;
    -webkit-transform: translateX(0.75rem);
    transform: translateX(0.75rem);
    left: calc(-2.85rem + 9px);
  }